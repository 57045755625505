<template>
	<div class="BatteryTest">
		<ul class="battery_info">
			<li>
				<span>是否正在充电(charging)</span>
				<span>{{ battery?.charging || "-" }}</span>
			</li>
			<li>
				<span>电池充满电所需要的时间(chargingTime)</span>
				<span>{{ battery?.chargingTime || "-" }}秒</span>
			</li>
			<li>
				<span>电池完全放电的剩余时间(dischargingTime)</span>
				<span>{{ battery?.dischargingTime || "-" }}秒</span>
			</li>
			<li>
				<span>当前电量(level)</span>
				<span>{{ battery?.level * 100 || "-" }}%</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	components: {},
	name: "BatteryTest",
	data() {
		return {
			battery: null,
		};
	},
	methods: {
		async getBatteryInfo() {
			if (navigator) {
				this.battery = await navigator.getBattery();
				// 电池的 charging 属性更新时触发。
				this.battery.addEventListener("chargingchange", this.chargingchange);
				// 电池的 chargingTime 属性更新时触发。
				this.battery.addEventListener("chargingtimechange", this.chargingtimechange);
				// 电池的 dischargingTime 属性更新时触发。
				this.battery.addEventListener("dischargingtimechange", this.dischargingtimechange);
				// 电池的 level 属性更新时触发。
				this.battery.addEventListener("levelchange", this.levelchange);
			} else {
				alert("Sorry your browser dosen't support the navigator object");
			}
		},
		chargingchange(e) {
			console.log("chargingchange", e);
		},
		chargingtimechange(e) {
			console.log("chargingtimechange", e);
		},
		dischargingtimechange(e) {
			console.log("dischargingtimechange", e);
		},
		levelchange(e) {
			console.log("levelchange", e);
		},
	},
	async mounted() {
		await this.getBatteryInfo();
	},
};
</script>
<style lang="less" scoped>
.BatteryTest {
	padding: 20px 60px;
	.battery_info {
		width: 800px;
		li {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			span {
				flex: 1 0 0;
			}
		}
	}
}
</style>
